<template>
  <div class="usersMsg">
    <div class="top clearfix">
      <p style="padding-left:15px;
          font-size:16px;
          margin-bottom: 5px;
          color: #333333;
          border-radius: 5px 5px 0 0;
          background-color: #E6ECF7;
          height: 35px; line-height: 35px;">基础信息</p>
      <table style="width: 100%; margin-left: 15px;">
        <tr>
          <td>
            <div class="pad">
              <span style="color: #333333;">用户ID：</span>
              <span>{{ userMsg.member_id ? userMsg.member_id : '' }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">手机号：</span>
              <span>{{ userMsg.mobile ? userMsg.mobile : "" }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">累计消耗积分：</span>
              <span>{{ userMsg.consum_point ? userMsg.consum_point : 0 }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">累计支付金额：</span>
              <span>{{ userMsg.sum_pay }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="pad">
              <span style="color: #333333;">姓名：</span>
              <span>{{ userMsg.nickname }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">账户余额：</span>
              <span>{{ userMsg.enable_money }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">产生订单数：</span>
              <span>{{ userMsg.order_num ? userMsg.order_num : "0" }}</span>
              <i class="goorder" @click="goorder" v-if="userMsg.order_num != 0">查看订单</i>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">加入时间：</span>
              <span>{{ userMsg.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="pad">
              <span style="color: #333333;">分组：</span>
              <span>{{ userMsg.group_name || "暂无" }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">加入方式：</span>
              <span>{{ userMsg.join_way_name }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">最后登录的时间：</span>
              <span>{{ userMsg.last_login | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="msgtable">
      <!-- 表单 -->
      <div class="tableList">
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="余额变动明细" name="first">
            <en-table-layout :toolbar="false" :tableData="tableData.data" @selection-change="handleSelectionChange">
              <template slot="table-columns">
                <el-table-column prop="group_name" label="变动时间" align="center">
                  <template slot-scope="scope">{{ scope.row.time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
                </el-table-column>
                <el-table-column prop="money" label="变动余额" align="center">

                  <template slot-scope="scope">{{ scope.row.money | unitPrice('￥') }}</template>
                </el-table-column>
                <el-table-column prop="reason" label="变动内容" align="center"></el-table-column>
                <el-table-column label="操作" show-overflow-tooltip align="center">

                  <template slot-scope="scope">
                    <el-button v-if="scope.row.money_type === 0" type="primary" size="mini"
                      @click="handleEdit(scope.$index, scope.row)">查看订单详情</el-button>
                  </template>
                </el-table-column>
              </template>
            </en-table-layout>

            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="handleSizeChange"
                  @current-change="handleCurrentChange" :current-page="tableData.page_no"
                  :page-size="tableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
                  :total="tableData.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="储值卡券变动明细" name="two">
            <en-table-layout :table-data="cardTableData.data">

              <template slot="toolbar">
                <div class="col-12">
                  <div class="row no-gutters align-items-center">
                    <el-form-item label="卡号">
                      <el-input v-model.trim="cardChangeParams.card_number" placeholder="请输入卡号" />
                    </el-form-item>
                    <el-form-item label="交易编号">
                      <el-input v-model.trim="cardChangeParams.trade_sn" placeholder="请输入交易编号" />
                    </el-form-item>
                    <el-button @click="searchCardChange" size="small" type="primary">
                      搜索
                    </el-button>
                    <div class="col"></div>
                    <el-button @click="handleExportCardChangeDetail" size="small" type="primary">
                      导出明细
                    </el-button>
                  </div>

                </div>
              </template>

              <template slot="table-columns">
                <el-table-column label="变动时间" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.create_date | unixToDate("yyyy-MM-dd hh:mm:ss") }}
                  </template>
                </el-table-column>
                <el-table-column label="变动类型" align="center">

                  <template slot-scope="scope">
                    {{ scope.row.change_type === 0 ? '消费' : '退款' }}
                  </template>
                </el-table-column>
                <el-table-column label="变动金额" align="center">

                  <template slot-scope="scope">
                    {{ scope.row.change_type === 0 ? `-${scope.row.amount_change}` : `+${scope.row.amount_change}` }}
                  </template>
                </el-table-column>
                <el-table-column label="变动内容" align="center" prop="reason" width="300" show-overflow-tooltip />
                <el-table-column label="储值卡券名称" prop="card_name" align="center" />
                <el-table-column label="储值卡号" prop="card_number" align="center" />
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="handleDetailSizeChange"
                  @current-change="handleDetailCurrentChange" :current-page="cardTableData.page_no"
                  :page-size="cardTableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
                  background :total="cardTableData.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="绑卡记录" name="three">
            <en-table-layout :toolbar="true" :tableData="bindCardList.data">

              <template slot="toolbar">
                <div style="display: flex; justify-content: space-between; width: 100%;">
                  <el-form-item label="绑卡时间">
                    <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
                      style="width: 220px;" v-model="daterangeDate" type="daterange" size="medium"
                      @change="daterangeDateChange" range-separator="-" start-placeholder="开始日期"
                      end-placeholder="结束日期"></el-date-picker>
                  </el-form-item>
                  <div style="display: flex;">
                    <el-form-item>
                      <el-input placeholder="请输入卡券名称" size="medium" prefix-icon="el-icon-search"
                        v-model="bindCardParams.card_name">
                      </el-input>
                    </el-form-item>
                    <div class="col-auto">
                      <el-button type="primary" @click="getUserChangeCard" size="small">
                        搜索
                      </el-button>
                    </div>
                  </div>
                </div>
              </template>

              <template slot="table-columns">
                <el-table-column prop="card_name" label="卡券名称" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="card_value" label="卡券面值" align="center"></el-table-column>
                <el-table-column label="绑卡时间" align="center">
                  <template slot-scope="scope">{{ scope.row.binding_time | unixToDate("yyyy-MM-dd hh:mm:ss")
                    }}</template>
                </el-table-column>
                <el-table-column prop="card_code" label="卡号" align="center"></el-table-column>
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="bindHandleSizeChange"
                  @current-change="bindHandleCurrentChange" :current-page="bindCardParams.page_no"
                  :page-size="bindCardParams.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
                  background :total="bindCardList.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="优惠券领取记录" name="four">
            <en-table-layout :toolbar="true" :tableData="memberKeyTableList.data">
              <template slot="toolbar">
                <div style="display: flex; justify-content: space-between; width: 100%;">
                  <el-form-item label="领取时间">
                    <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
                      style="width: 220px;" v-model="memberKeyDaterangeDate" type="daterange" size="medium"
                      @change="memberKeyDateChange" range-separator="-" start-placeholder="开始时间"
                      end-placeholder="结束时间"></el-date-picker>
                  </el-form-item>
                  <div style="display: flex;">
                    <el-form-item>
                      <el-input placeholder="请输入优惠券名称" size="medium" prefix-icon="el-icon-search"
                        v-model="memberKeyParams.coupon_name">
                      </el-input>
                    </el-form-item>
                    <div class="col-auto">
                      <el-button type="primary" @click="getUserMemberKeyCard" size="small">
                        搜索
                      </el-button>
                    </div>
                  </div>
                </div>
              </template>

              <template slot="table-columns">
                <el-table-column prop="coupon_name" label="优惠券名称" show-overflow-tooltip
                  align="center"></el-table-column>
                <el-table-column prop="coupon_type" label="优惠券类型" align="center">
                  <template slot-scope="{row}">
                    {{ row.coupon_type == 1 ? '折扣卷' : '满减卷' }}
                  </template>
                </el-table-column>
                <el-table-column prop="coupon_content" label="优惠内容" align="center"></el-table-column>
                <el-table-column prop="coupon_code" label="卷码ID" align="center"></el-table-column>
                <el-table-column prop="coupon_key" label="兑换码" align="center"></el-table-column>
                <el-table-column label="领取时间" align="center">

                  <template slot-scope="scope">
                    <span v-if="scope.row.binding_time">{{ scope.row.binding_time | unixToDate("yyyy-MM-dd hh: mm: ss")}}</span>
                    <span v-else></span>
                  </template>
                </el-table-column>
                <el-table-column label="使用时间" align="center">

                  <template slot-scope="scope">
                    <span v-if="scope.row.use_time">{{ scope.row.use_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
                    <span v-else>——</span>
                  </template>
                </el-table-column>
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="memberKeySizeChange"
                  @current-change="memberKeyCurrentChange" :current-page="memberKeyParams.page_no"
                  :page-size="memberKeyParams.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
                  background :total="memberKeyTableList.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="goods_distribution_open" label="收益账户变动记录" name="five">
            <en-table-layout :toolbar="true" :tableData="revenueAccountList.data">
              <template slot="toolbar">
                <h4>收益账户余额：￥{{userMsg.distribution_income_money}}</h4>
                <div style="display: flex; justify-content: space-between; width: 100%;">
                  <el-form-item label="明细类型">
                    <el-select style="width: 110px" v-model="changeRecordParams.biz_type" size="small" placeholder="请选择" clearable>
                      <el-option label="全部" value="" />
                      <el-option label="收入" :value="1" />
                      <el-option label="支出" :value="2" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="变动时间">
                    <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
                      style="width: 220px;" @change="changeRecordDateChange" v-model="changeRecordDate" type="daterange" size="medium"
                         range-separator="-" start-placeholder="开始时间"
                      end-placeholder="结束时间"></el-date-picker>
                  </el-form-item>
                  <div style="display: flex;">
                    <div class="col-auto">
                      <el-button type="primary" @click="getChangeRecordSearch" size="small">
                        搜索
                      </el-button>
                      <el-button @click="handleExportAccount" size="small" type="primary">导出明细</el-button>
                    </div>
                  </div>
                </div>
              </template>
              <template slot="table-columns">
                <el-table-column label="变动时间" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.create_time">{{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
                    <span v-else>——</span>
                  </template>
                </el-table-column>
                <el-table-column prop="biz_type" label="明细类型" align="center">
                  <template slot-scope="{row}">
                    {{ formaterChangeRecordType(row) }}
                  </template>
                </el-table-column>
                <el-table-column prop="content" label="明细内容" align="center"></el-table-column>
                <el-table-column prop="income" label="收益账户变动金额（元）" align="center">
                  <template slot-scope="{row}">
                    {{ formaterChangeRecordMonery(row) }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" show-overflow-tooltip align="center">
                  <template slot-scope="scope">
                    <el-button type="primary" size="mini"
                      @click="changeRecordInfo(scope.row)">详情</el-button>
                  </template>
                </el-table-column>
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="changeRecordSizeChange"
                  @current-change="changeRecordCurrentChange" :current-page="changeRecordParams.page_no"
                  :page-size="changeRecordParams.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
                  background :total="revenueAccountList.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="goods_distribution_open" label="TA的下级" name="six">
            <en-table-layout :toolbar="true" :tableData="subordinatesData.data">
              <template slot="toolbar">
                <el-form-item label="绑定时间">
                  <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
                    style="width: 220px;" v-model="subordinatesDate" type="daterange" size="medium"
                    @change="subordinatesDateChange" range-separator="-" start-placeholder="开始时间"
                    end-placeholder="结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-input style="width: 250px" v-model.trim="keywords" placeholder="请输入关键字搜索" clearable>
                    <el-select style="width: 80px;" v-model="search_type" slot="prepend">
                      <el-option label="昵称" value="nickname"></el-option>
                      <el-option label="账号" value="uname"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getSubordinatesSearch" size="small">搜索</el-button>
                </el-form-item>
              </template>

              <template slot="table-columns">
                <el-table-column label="绑定时间" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.create_time">{{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
                    <span v-else>——</span>
                  </template>
                </el-table-column>
                <el-table-column prop="nickname" label="下级好友昵称" show-overflow-tooltip align="center" />
                <el-table-column prop="mobile" label="下级用户账号" show-overflow-tooltip align="center">
                  <template slot-scope="{row}">
                    <div v-if="row.mobile && row.uname">
                      <p>{{ row.mobile }}</p>
                      {{ row.uname }}
                    </div>
                    <div v-else>
                      {{ row.mobile }}
                      {{ row.uname }}
                    </div>
                  </template>
                </el-table-column>  
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="subordinatesSizeChange"
                  @current-change="subordinatesCurrentChange" :current-page="subordinatesParams.page_no"
                  :page-size="subordinatesParams.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
                  background :total="subordinatesData.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 收益账户变动记录详情 -->
    <el-dialog-x-dialog title="详情" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="650px" :visible.sync="messageVisible">
      <el-form class="orderDetail" :model="revenueAccountData" label-width="140px">
        <el-form-item label="明细类型">{{ formaterChangeRecordType(revenueAccountData) }}</el-form-item>
        <el-form-item label="明细内容">{{ revenueAccountData.content }}</el-form-item>
        <!-- 账户收入 - 获取下级用户订单消费返佣 -->
        <template v-if="revenueAccountData.biz_type==0">
          <el-form-item label="下级用户">{{ revenueAccountData.from_member_nickname }}({{ revenueAccountData.frome_member_mobile }})</el-form-item>
          <el-form-item label="下级订单编号">{{ revenueAccountData.order_sn }}</el-form-item>
          <el-form-item label="下级订单参与返佣商品总额">{{ revenueAccountData.order_price | unitPrice('￥')}}</el-form-item>
          <el-form-item label="收益金额">{{ revenueAccountData.income| unitPrice('￥') }}</el-form-item>
          <el-form-item label="下级用户支付时间">{{ revenueAccountData.order_payment_time | unixToDate}}</el-form-item>
        </template>
         <!-- 账户收入—订单取消/售后金额退回 -->
        <template v-if="[3,6].includes(revenueAccountData.biz_type)">
          <el-form-item label="订单编号">{{ revenueAccountData.order_sn }}</el-form-item>
          <el-form-item label="退回金额">{{ revenueAccountData.order_cancle_after_sale_amount| unitPrice('￥') }}</el-form-item>
          <el-form-item label="到账时间">{{ revenueAccountData.order_cancle_after_sale_revenue_time | unixToDate}}</el-form-item>
        </template>
        <!-- 账户支出 - 提现到银行卡 -->
        <template v-if="[1,5].includes(revenueAccountData.biz_type)">
          <el-form-item label="到账银行卡">{{ revenueAccountData.distribution_income_cash_withdraw_do.id_card_number }}</el-form-item>
          <el-form-item label="提现金额">{{ revenueAccountData.distribution_income_cash_withdraw_do.amount| unitPrice('￥') }}</el-form-item>
          <el-form-item label="提现手续费">{{ revenueAccountData.distribution_income_cash_withdraw_do.commission | unitPrice('￥')}}</el-form-item>
          <el-form-item label="实际到账金额">{{ revenueAccountData.distribution_income_cash_withdraw_do.revenue_amount| unitPrice('￥') }}</el-form-item>
          <el-form-item label="提现发起时间">{{ revenueAccountData.distribution_income_cash_withdraw_do.create_time | unixToDate}}</el-form-item>
          <el-form-item label="提现状态">{{ revenueAccountData.distribution_income_cash_withdraw_do.apply_status | apply_statusFilter}}</el-form-item>
          <el-form-item v-if="revenueAccountData.biz_type==5" label="失败原因">{{ revenueAccountData.distribution_income_cash_withdraw_do.audit_reject_remark | unixToDate}}</el-form-item>
        </template>
        <!-- 账户支出 - 订单消费支出 -->
        <template v-if="revenueAccountData.biz_type==2">
          <el-form-item label="订单编号">{{ revenueAccountData.order_sn }}</el-form-item>
          <el-form-item label="支出金额">{{ revenueAccountData.expenses_amount| unitPrice('￥') }}</el-form-item>
          <el-form-item label="支付时间">{{ revenueAccountData.expenses_time | unixToDate}}</el-form-item>
        </template>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_Users from "@/api/users";
import * as API_member from "@/api/member";
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import { handleDownload } from "@/utils";
import { Foundation } from '@/../ui-utils'
import { getSellerShopExtAuth } from "@/api/account";
export default {
  name: "usersMsg",
  components: { EnTableLayout },
  filters: {
    /**提现状态 */
    apply_statusFilter(apply_status) {
      const temp=['提现中','打款中','已完成','提现失败','已拒绝']
      return temp[apply_status]
    }
  },
  data () {
    return {
      total: 0,//暂无使用该字段
      flag: false,//暂无使用该字段
      currentPage4: 4,//暂无使用该字段
      formInline: {//暂无使用该字段
        user: "",
        region: ""
      },
      multipleSelection: [],//暂无使用该字段
      activeName: "first",//tab页签默认选中字段
      inform: {//余额变动明细查询参数
        page_size: 20,
        page_no: 1,
        member_id: 0
      },
      userMsg: {},//顶部基础信息字段
      tableData: {},//余额变动明细表格数据

      cardChangeParams: {// 代金卡券变动明细查询参数
        page_no: 1,
        page_size: 20,
        member_id: this.$route.query.id
      },
      cardTableData: {//代金卡卷变动明细data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 1
      },

      bindCardList: {//绑卡记录data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      bindCardParams: {//绑卡记录查询参数
        page_no: 1,
        page_size: 20,
        card_type: 3,
        binding_member_id: this.$route.query.id
      },
      daterangeDate: '',//绑卡记录tab 时间查询字段 

      memberKeyParams: {//优惠券领取记录查询参数
        page_no: 1,
        page_size: 20,
        binding_member_id: this.$route.query.id
      },
      memberKeyDaterangeDate: '',//优惠券领取记录tab 时间查询字段
      memberKeyTableList: {//优惠券领取记录data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      changeRecordParams: {//收益账户变动记录查询参数
        page_no: 1,
        page_size: 20,
        biz_type:'',
        member_id: this.$route.query.id
      },
      changeRecordDate: [],//收益账户变动记录tab 时间查询字段
      revenueAccountData: {},//收益账户变动记录详情
      messageVisible: false,//收益账户变动记录详情弹窗
      revenueAccountList:{//收益账户变动记录data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      subordinatesParams: {//TA的下级查询参数
        page_no: 1,
        page_size: 20,
        lv1_member_id: this.$route.query.id
      },
      keywords: '',//搜索框搜索字段
      search_type: 'nickname',
      subordinatesDate:[],//TA的下级tab 时间查询字段
      subordinatesData:{//TA的下级data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      goods_distribution_open: false,//是否有分销权限
    };
  },
  async created () {
    //查询余额变动明细
    this.getmemberMsg();
    const resStatus = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
    if (resStatus) {
      this.goods_distribution_open = resStatus.goods_distribution_open === "OPEN";
    }
  },
  methods: {
    goorder () {// 基础信息查看订单
      console.log("订单");
      this.$router.push(`/order/order-list?member_id=${this.userMsg.member_id}`);
    },
    
    handleClick () {
      console.log(this.activeName);
      
      switch (this.activeName) {
        case 'first':
          this.getmemberMsg();//余额变动明细
          break;
        case 'two':
          this.searchCardChange();//代金卡卷变动明细     
          break;
        case 'three':
          this.getUserChangeCard();//绑卡记录
          break;
        case 'four':
          this.getUserMemberKeyCard();//优惠券领取记录
          break;
        case 'five':
          this.getChangeRecordList();//收益账户变动记录
          break;
        case 'six':
          this.getSubordinatesList();//TA的下级
          break;
      }
    },

    /**
     * @val 余额变动明细开始
     */
    handleSizeChange (val) { // 余额变动明细选择每页最大条数
      this.inform.page_size = val;
      console.log(`每页 ${val} 条`);
      this.getmemberMsg();
    },
    handleCurrentChange (val) {//余额变动明细切换页
      this.inform.page_no = val;
      console.log(`当前页: ${val}`);
      this.getmemberMsg();
    },
    getmemberMsg () {//余额变动明细查询
      this.inform.member_id = this.$route.query.id;
      API_member.getmembersgetInfo(this.inform.member_id).then(res => {
        this.userMsg = res;
      });
      API_Users.memberMoney(this.inform).then(res => {
        this.tableData = res;
      });
    },
    handleSelectionChange (val) {//余额变动明细复选框事件
      this.multipleSelection = val;
    },
    handleEdit (index, row) {//余额变动明细查看详情
      this.$router.push(`/order/order-list/detail/${row.sn}`);
    },

    /**
     * @val 代金卡卷变动明细
     */
    searchCardChange () {// 代金卡卷搜索事件
      this.cardChangeParams.page_no = 1;
      if (!this.cardChangeParams.card_number) {
        delete this.cardChangeParams.card_number
      }
      if (!this.cardChangeParams.trade_sn) {
        delete this.cardChangeParams.trade_sn
      }
      this.getCardChangeList();
    },
    handleDetailSizeChange (val) {//切换分页
      this.cardChangeParams.page_size = val;
      this.getCardChangeList();
    },
    handleDetailCurrentChange (val) {//切换分页
      this.cardChangeParams.page_no = val;
      this.getCardChangeList();
    },
    getCardChangeList () {//代金卡卷查询列表
      API_member.getMemberCardChange(this.cardChangeParams).then(res => {
        this.cardTableData = res;
      })
    },
    handleExportCardChangeDetail () { // 储值卡券变动明细导出
      const headerVal = [
        '变动时间',
        '变动类型',
        '变动金额',
        '变动内容',
        '储值卡券名称',
        '储值卡号'
      ]
      const contentVal = [
        'create_date',
        'changeType',
        'amount_change',
        'reason',
        'card_name',
        'card_number'
      ]
      let params = {
        ...this.cardChangeParams
      }
      params.page_size = 999999;
      API_member.getMemberCardChange(params).then(res => {
        let data = res.data.map(item => {
          item['create_date'] = Foundation.unixToDate(item.create_date);
          item['changeType'] = item.change_type == 0 ? '消费' : '退款';
          item['amount_change'] = item.change_type == 0 ? `-${item.amount_change}` : `+${item.amount_change}`;
          return item
        })
        handleDownload(data, headerVal, contentVal, "储值卡券变动记录");
      })
    },

    /**
     * @val 绑卡记录开始
     */
    bindHandleSizeChange (val) {//绑卡记录切换分页
      this.bindCardParams.page_size = val
      this.getUserChangeCard()
    },
    bindHandleCurrentChange (val) {//绑卡记录切换分页
      this.bindCardParams.page_no = val
      this.getUserChangeCard()
    },
    daterangeDateChange (val) {//绑卡记录时间格式化
      this.bindCardParams.binding_start = val ? val[0] / 1000 : '';
      this.bindCardParams.binding_end = val ? val[1] / 1000 : '';
    },
    getUserChangeCard () {//绑卡记录查询
      API_Users.getUserChangeCardList(this.bindCardParams).then(res => {
        console.log(res)
        this.bindCardList = res
      })
    },

    /**
     * @val 优惠券领取记录
     */
    getUserMemberKeyCard () {// 优惠券搜索事件
      this.memberKeyParams.page_no = 1;
      if (!this.memberKeyParams.card_number) {
        delete this.memberKeyParams.card_number
      }
      if (!this.memberKeyParams.trade_sn) {
        delete this.memberKeyParams.trade_sn
      }
      this.getUserMemberKeyList();
    },
    memberKeySizeChange (val) {//优惠券领取记录切换分页
      this.bindCardParams.page_size = val
      this.getUserMemberKeyList()
    },
    memberKeyCurrentChange (val) {//优惠券领取记录切换分页
      this.bindCardParams.page_no = val
      this.getUserMemberKeyList()
    },
    memberKeyDateChange (val) {//优惠券领取记录时间格式化
      this.memberKeyParams.min_binding_time = val ? val[0] / 1000 : '';
      this.memberKeyParams.max_binding_time = val ? val[1] / 1000 : '';
    },
    getUserMemberKeyList () {//优惠券领取记录
      API_Users.getUserMemberKeyList(this.memberKeyParams).then(res => {
        this.memberKeyTableList = res.data;
      })
    },

    /**
     * @val 收益账户变动记录
     */
     getChangeRecordSearch () {// 收益账户变动搜索事件
      this.changeRecordParams.page_no = 1;
      this.getChangeRecordList();
    },
     /**格式化明细类型 */
     formaterChangeRecordType (row) {
      if ([0,3,4,5,6].includes(row.biz_type)) {
        return '收入'
      } else {
        return '支出'
      }
    },
    /**格式化收益账户变动金额 */
    formaterChangeRecordMonery (row) {
      switch (row.biz_type) {
        case 0:
          return row.income
        case 1:
          return row.distribution_income_cash_withdraw_amount
        case 2:
          return row.expenses_amount
        case 3:
          return row.order_cancle_after_sale_amount
        case 4:
          return row.distribution_income_cash_withdraw_amount
        case 5:
          return row.distribution_income_cash_withdraw_amount
        case 6:
          return row.order_cancle_after_sale_amount
      }
    },
    changeRecordSizeChange (val) {//收益账户变动记录切换分页
      this.changeRecordParams.page_size = val
      this.getChangeRecordList()
    },
    changeRecordCurrentChange (val) {//收益账户变动记录切换分页
      this.changeRecordParams.page_no = val
      this.getChangeRecordList()
    },
    /**导出收益账户变动记录 */
    handleExportAccount () {
      const tHeaders = [
          "变动时间",
          "明细类型",
          "明细内容",
          "收益账户变动金额"
        ];
        const filterVals = [
          "create_time",
          "biz_type_text",
          "content",
          "distribution_income_money"
      ];
      const changeRecordParams = {
        ...this.changeRecordParams
      }
      changeRecordParams.page_no = 1;
      changeRecordParams.page_size = 99999;
      API_Users.getEarningsList(changeRecordParams).then(res => {
        res.data.forEach(item => {
          item.create_time = Foundation.unixToDate(item.create_time);
          item.biz_type_text = this.formaterChangeRecordType(item);
          item.distribution_income_money = this.formaterChangeRecordMonery(item);
        })
        handleDownload(res.data, tHeaders, filterVals, "收益账户变动记录");
      })
    },
    changeRecordDateChange (val) {//收益账户变动记录时间格式化
      this.changeRecordParams.create_time_start = val ? val[0] / 1000 : '';
      this.changeRecordParams.create_time_end = val ? val[1] / 1000 : '';
    },
    /**查询收益账户变动记录详情 */
    changeRecordInfo (row) {
      API_Users.getEarningsInfo(row.id).then(res => {
        this.revenueAccountData = res;
        this.messageVisible=true;
      })
    },
    getChangeRecordList () { //收益账户变动记录
      if (this.search_type) {
        this.subordinatesParams[this.search_type] = this.keywords;
      }
      API_Users.getEarningsList(this.changeRecordParams).then(res => {
        this.revenueAccountList = res;
      })
    },

    /**
     * @val TA的下级
     */
     getSubordinatesSearch () {// TA的下级搜索事件
      this.subordinatesParams.page_no = 1;
      if (!this.subordinatesParams.card_number) {
        delete this.subordinatesParams.card_number
      }
      if (!this.subordinatesParams.trade_sn) {
        delete this.subordinatesParams.trade_sn
      }
      this.getSubordinatesList();
    },
    subordinatesSizeChange (val) {//TA的下级切换分页
      this.subordinatesParams.page_size = val
      this.getSubordinatesList()
    },
    subordinatesCurrentChange (val) {//TA的下级切换分页
      this.subordinatesParams.page_no = val
      this.getSubordinatesList()
    },

    subordinatesDateChange (val) {//TA的下级时间格式化
      this.subordinatesParams.create_time_start = val ? val[0] / 1000 : '';
      this.subordinatesParams.create_time_end = val ? val[1] / 1000 : '';
    },
    getSubordinatesList () { //TA的下级列表查询
      API_Users.getLowerList(this.subordinatesParams).then(res => {
        this.subordinatesData = res;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.top {
  //border: 1px solid #666;
  color: #666666;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #fff;
  padding-bottom: 10px;
}

/deep/ .el-tabs__content {
  padding-top: 0;
}

.goorder {
  margin-left: 25px;
  color: #0099ff;
  font-style: normal;
  cursor: pointer;
}

.top .msgleft {
  float: left;
}

.top .msgright {
  float: left;
  margin-left: 130px;
}

.pad {
  padding: 4px;
}
.orderDetail {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
